import { Controller } from "@hotwired/stimulus";
import Decimal from "decimal.js";

export default class PriceController extends Controller {
  static targets = ["price", "discount", "fullPrice"];

  updatePrice() {
    const fullPrice = this.getFullPrice();
    const discount = this.getDiscount();

    this.priceTarget.value = fullPrice
      .minus(discount)
      .toDecimalPlaces(2)
      .toNumber();
  }

  updateDiscount() {
    const fullPrice = this.getFullPrice();
    const price = this.getPrice();

    this.discountTarget.value = fullPrice
      .minus(price)
      .toDecimalPlaces(2)
      .toNumber();
  }

  getPrice() {
    return Decimal(this.priceTarget.value || 0);
  }

  getDiscount() {
    return Decimal(this.discountTarget.value || 0);
  }

  getFullPrice() {
    return Decimal(this.fullPriceTarget.value || 0);
  }
}
