import {Controller} from "@hotwired/stimulus";

import Chart from 'chart.js/auto';

export default class ChartController extends Controller {
  static values = {
    data: Object,
    type: String,
    options: Object
  }

  connect() {
    const options = {responsive: true, maintainAspectRatio: false, ...this.optionsValue}
    const context = this.element;

    new Chart(context, {
      type: this.typeValue,
      data: this.dataValue,
      options: options
    });
  }
}