import { Controller } from "@hotwired/stimulus";

import { Loader } from "@googlemaps/js-api-loader";

export default class TerritoryController extends Controller {
  static values = {
    geoJson: String,
    locationJson: String,
  };

  connect() {
    const geoJson = JSON.parse(this.geoJsonValue);
    const locationJson = JSON.parse(this.locationJsonValue);

    const loader = new Loader({
      apiKey: "AIzaSyD__ynP0lRucLo-eQc5hxETCQgVmDup0Qc",
      version: "weekly",
    });

    loader.importLibrary("maps").then(async () => {
      google.maps.importLibrary("maps").then(({ Map, Marker }) => {
        const map = new Map(document.getElementById("franchisee-territory"), {
          center: {
            lat: locationJson.coordinates[1],
            lng: locationJson.coordinates[0],
          },
          zoom: 14,
          streetViewControl: false,
        });

        map.data.addGeoJson({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: geoJson,
            },
          ],
        });

        new google.maps.Marker({
          position: {
            lat: locationJson.coordinates[1],
            lng: locationJson.coordinates[0],
          },
          map,
          title: "Endereço da Franquia",
        });
      });
    });
  }
}
