import { Controller } from "@hotwired/stimulus";

export default class ZipCodeController extends Controller {
  static targets = ["zipCode", "streetAddress", "district", "city"];

  search() {
    const zipCode = this.zipCodeTarget.value.replace("-", "");

    if (zipCode.length !== 8) {
      return;
    }

    const url = `https://viacep.com.br/ws/${zipCode}/json/`;

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        if (json.erro) {
          return;
        }

        this.zipCodeTarget.value = json.cep.replace("-", "");
        this.streetAddressTarget.value = json.logradouro;
        this.districtTarget.value = json.bairro;

        const city = new Option(
          `${json.localidade} - ${json.uf}`,
          json.ibge,
          true,
          true
        );

        this.cityTarget.options.length = 0;
        this.cityTarget.append(city);
        this.cityTarget.value = json.ibge;
      })
      .catch((error) => console.log(error));
  }
}
