import { Controller } from "@hotwired/stimulus";

import SlimSelect from "slim-select";

export default class SimpleSelectController extends Controller {
  static values = {
    url: String,
    perPage: Number,
    scope: String,
    minChars: Number,
  };

  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        searchText: "Nenhum resultado encontrado",
        searchPlaceholder: "Selecione uma opção",
        searchingText: "Pesquisando...",
        placeholderText: "Pesquisar...",
      },
    });
  }
}
