import { Controller } from "@hotwired/stimulus";

import { Tab } from "bootstrap";

export default class TabController extends Controller {
  connect() {
    const tabTrigger = new Tab(this.element);

    this.element.addEventListener("click", function (event) {
      event.preventDefault();
      tabTrigger.show();
    });
  }
}
