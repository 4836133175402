import Decimal from "decimal.js";

const BRL = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function withdrawalCalculator() {
  const checkboxes = document.querySelectorAll(".withdrawal-checkbox:checked");

  let amount = new Decimal(0);
  const withdrawalFee = 3.67;

  checkboxes.forEach((checkbox) => {
    amount = amount.plus(new Decimal(checkbox.dataset.value));
  });

  document.getElementById("withdrawal-amount").innerHTML = BRL.format(
    amount.toNumber()
  );

  document.getElementById("withdrawal-liquid-amount").innerHTML = BRL.format(
    amount.minus(withdrawalFee).toNumber()
  );
}
