import { Controller } from "@hotwired/stimulus";
import Decimal from "decimal.js";

export default class OrderPriceController extends Controller {
  static targets = ["itemPrice", "orderPrice"];

  calculatePrice() {
    const price = this.itemPriceTargets.reduce((sum, target) => {
      return Decimal(sum).plus(target.value || 0);
    }, 0);

    this.orderPriceTarget.innerText = price.toFixed(2);
  }
}
