import CheckboxSelectAll from "stimulus-checkbox-select-all";

import { withdrawalCalculator } from "../utils/withdrawal_caculator";

export default class SelectAll extends CheckboxSelectAll {
  connect() {
    super.connect();

    this.checked;
    this.unchecked;
  }

  toggle(e) {
    super.toggle(e);

    withdrawalCalculator();
  }
}
