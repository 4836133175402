import { Controller } from "@hotwired/stimulus";

import SlimSelect from "slim-select";

export default class SelectController extends Controller {
  static values = {
    url: String,
    perPage: Number,
    scope: String,
    minChars: Number,
  };

  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        searchText: "Nenhum resultado encontrado",
        searchPlaceholder: "Selecione uma opção",
        searchingText: "Pesquisando...",
        placeholderText: "Pesquisar...",
      },
      events: {
        search: (search, _currentData) => {
          return new Promise((resolve, reject) => {
            const minChars = this.minChars || 2;

            if (search.length < minChars) {
              return reject(`Digite mais de ${minChars} caracteres`);
            }

            const url = new window.URL(this.urlValue, window.location.origin);
            const params = new window.URLSearchParams();

            params.append("search", search);
            params.append("per_page", this.perPageValue || 20);

            if (this.scopeValue) {
              params.append("scope", this.scopeValue);
            }

            url.search = params.toString();

            fetch(url)
              .then((response) => response.json())
              .then((json) => {
                const data = json.map((j) => ({ text: j.name, value: j.id }));

                data.push({
                  text: "Remover",
                  value: null,
                });

                resolve(data);
              })
              .catch((_error) => reject(false));
          });
        },
      },
    });
  }
}
